import React from 'react'
import { Link,useLocation } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SEO from '../Components/SEO';
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {works} from './workitem'
import { Autoplay,FreeMode, Navigation, Thumbs } from "swiper";
function Section31() {
  return (
    <div
      className='w-full bg-[#F9F9F9] bg-cover bg-center bg-no-repeat min-h-screen'
      style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/section03-bg.png' })`}}
    >
      <SEO
        title={`翊鼎國際行銷 - 熱銷個案`} 
        description={`生活就像一朵花，越用心灌溉，會開出最美的模樣。`}
        name={`翊鼎國際行銷`}
        type={`digital marketing`}
        image={process.env.PUBLIC_URL+'/images/logo.png'}
      />
      <div className='w-full flex flex-col  md:pl-[230px]  '>

        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={10}
          navigation={true}
          modules={[Autoplay,FreeMode, Navigation, Thumbs]}
          className="mySwiper2 w-full"
          onSlideChange={(swiperCore)=>{
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;
          }}
        >
          {
            works &&
            works.map((item,index)=>{
              return(
                <SwiperSlide key={'banner'+index} >
                   <Link  to={`/work?id=${item.id}`} className=' transition-all duration-500 hover:brightness-105'>
                      <img src={process.env.PUBLIC_URL+'/images/works/banner'+item.id+'.png'} alt={item.title}  className="w-full"/>
                  </Link>
                </SwiperSlide>
              )
            })
          }

        </Swiper>
        <div className=' grid grid-cols-2  md:grid-cols-4 w-9/12 gap-4 md:gap-8 mx-auto mt-12 pb-20'>
          {works.map((item,index)=>{
            return(
              <Link key={'cover'+index} to={`/work?id=${item.id}`} className=' transition-all duration-500 hover:brightness-105 hover:scale-110'>
                 <img src={process.env.PUBLIC_URL+'/images/works/cover'+item.id+'.png'} alt={item.title} className="w-full"/>
              </Link>
            )
          })}
        </div>

        
      </div>
    </div>
  )
}

export default Section31