import React from 'react'
import SEO from '../Components/SEO'
function Section2() {
  return (
    <div
      className='w-full bg-[#F9F9F9] bg-cover bg-center bg-no-repeat min-h-screen'
      style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/section02-bg.png' })`}}
    >
      <SEO
        title={`翊鼎國際行銷 - 團隊介紹`} 
        description={`生活就像一朵花，越用心灌溉，會開出最美的模樣。`}
        name={`翊鼎國際行銷`}
        type={`digital marketing`}
        image={process.env.PUBLIC_URL+'/images/logo.png'}
      />
      <div className=' flex h-full items-center justify-between   pt-[20%] md:pt-0'>

          <div className='md:w-full md:p-[8%] md:ml-[250px] w-10/12 mx-auto'>
            <div className=' tracking-[4px] font-bold text-[#4D4D4D] text-xl flex items-center pb-8'>組織架構 <span className='ml-5 text-base tracking-[1px]'>/Organization</span></div>
            <div className='md:w-10/12 mx-auto'>
              <img src={process.env.PUBLIC_URL+'/images/section02_p01.png'} alt="" className='max-w-full w-full hidden md:block' data-aos="fade-up" data-aos-duration="1000"/>
              <img src={process.env.PUBLIC_URL+'/images/section02_p01_mb.png'} alt="" className='max-w-full w-full mt-5 md:hidden' data-aos="fade-up" data-aos-duration="1000"/>
            </div>
           
          </div>
  
        
      </div>
    </div>
  )
}

export default Section2