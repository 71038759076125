import React from 'react'
import SEO from '../Components/SEO'
function Section11() {
  return (
    <div
      className='w-full bg-[#F9F9F9] bg-cover bg-center bg-no-repeat min-h-screen'
      style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/section01-bg.png' })`}}
    >
      <SEO
        title={`翊鼎國際行銷 - 創辦心誌`} 
        description={`生活就像一朵花，越用心灌溉，會開出最美的模樣。`}
        name={`翊鼎國際行銷`}
        type={`digital marketing`}
        image={process.env.PUBLIC_URL+'/images/logo.png'}
      />
      <div className='pt-[22%] md:pt-[15%] '>
        <div className=' flex flex-col md:flex-row  w-full  items-center h-full  '>
          <div className='w-11/12 mx-auto md:w-1/2 md:ml-[300px] flex justify-center'>
            <img src={process.env.PUBLIC_URL+'/images/section01-text.png'} alt="" className='max-w-full ' data-aos="fade-up" data-aos-duration="1000"/>
          </div>
          <div className='md:w-1/2 flex justify-center gap-10'>
            <div className='w-[35%]'>
              <img src={process.env.PUBLIC_URL+'/images/section01-p01.png'} alt="" className='max-w-full w-full' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300"/>
            </div>
            <div className='w-[35%] pt-14'>
              <img src={process.env.PUBLIC_URL+'/images/section01-p02.png'} alt="" className='max-w-full w-full' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300"/>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Section11