import React from 'react'
import {  useParams,Link,useNavigate } from 'react-router-dom';
import {works} from './workitem'
import { MdKeyboardArrowLeft } from "react-icons/md";
import SEO from '../Components/SEO';
function Work() {
  // const { id } = useParams();
  // console.log(id)
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  // 根据id查找数据
  if(!params.id){
    setTimeout(()=>{
      navigate('/section3-1');
    },500)
    return
  }
  const workData = works.find((work) => work.id === params.id);
  const currentEnv = 'production'
  // development  production
  const PUBLICURL = currentEnv === 'development' ?  process.env.PUBLIC_URL : '..'

  if (!workData) {
    setTimeout(()=>{
      navigate('/section3-1');
    },500)
    return
  }
  return (
    <div
      className='w-full bg-[#F9F9F9] bg-cover bg-center bg-no-repeat min-h-screen'
      style={{backgroundImage: `url(${PUBLICURL + '/images/section03-bg.png' })`}}
    >
      <SEO
        title={`翊鼎國際行銷 - ${workData.title}`} 
        description={`${workData.base} ${workData.centre} ${workData.floor} ${workData.size} ${workData.company} `}
        name={`翊鼎國際行銷`}
        type={`digital marketing`}
        image={PUBLICURL+'/images/works/'+workData.banner}
      />
      <div className='w-full flex flex-col   md:pl-[230px]  '>

        {/* content */}
        <div className='w-full'>
          <img src={PUBLICURL+'/images/works/'+workData.banner} alt="" className='w-full' data-aos="fade" data-aos-duration="1000"/>
        </div>
        <Link className='flex items-center p-2' to='/section3-1'>
          <MdKeyboardArrowLeft size={20} /> <div className=' uppercase font-sans text-sm'>Back</div>
        </Link>
        <div className='mt-8 pb-14 flex flex-col md:flex-row w-10/12  justify-center gap-10 md:gap-16 items-center  mx-auto' data-aos="fade" data-aos-duration="1000" data-aos-delay="300">
          <div className='md:w-8/12'> 
            <img src={PUBLICURL+'/images/works/work'+workData.id+'.png'} alt={workData.title} className='max-w-full w-full'  />
          </div>
          <div className='md:w-4/12' >
            <div className='text-2xl font-semibold tracking-widest'>{workData.title}</div>
            <div className='text-xs mt-6 flex flex-col  space-y-2 font-sans'>
              <div className='flex'><span className=' break-keep mr-1'>基地位置:</span> <span>{workData.base}   </span>  </div>
              <div className='flex'><span className=' break-keep mr-1'>接待中心:</span> <span>{workData.centre} </span>  </div>
              <div className='flex'><span className=' break-keep mr-1'>樓層戶數:</span> <span>{workData.floor}  </span>  </div>
              <div className='flex'><span className=' break-keep mr-1'>產品規劃:</span> <span>{workData.size}   </span>  </div>
              <div className='flex'><span className=' break-keep mr-1'>投資興建:</span> <span>{workData.company}</span>  </div>
            </div>
            <div className='flex gap-4 mt-8 md:mt-16  '>
              {workData.website && 
                <a className='w-14 hover:scale-105 transition-all' href={workData.website} target='_blank' rel="noreferrer"> 
                  <img src={PUBLICURL+'/images/works/site.png'} alt={workData.title} className='max-w-full w-full' />
                </a> 
              }
              {workData.fb && 
                <a className='w-14 hover:scale-105 transition-all' href={workData.fb} target='_blank' rel="noreferrer"> 
                  <img src={PUBLICURL+'/images/works/fb.png'} alt={workData.title} className='max-w-full w-full' />
                </a> 
              }
              {workData.yt && 
                <a className='w-14 hover:scale-105 transition-all' href={workData.yt} target='_blank' rel="noreferrer"> 
                  <img src={PUBLICURL+'/images/works/yt.png'} alt={workData.title} className='max-w-full w-full' />
                </a> 
              }
              {workData.house591 && 
                <a className='w-14 hover:scale-105 transition-all' href={workData.house591} target='_blank' rel="noreferrer"> 
                  <img src={PUBLICURL+'/images/works/house591.png'} alt={workData.title} className='max-w-full w-full' />
                </a> 
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work