import React,{useState,useEffect} from 'react'
import { useForm, reset } from "react-hook-form";
import SEO from '../Components/SEO';
function Section4() {
  const [msgMailTitle,setMsgMailTitle] = useState('翊鼎官網的表單')
  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [formStatus , setFormStatus] = useState(false)
  const { register, handleSubmit, formState:{ errors },reset } = useForm({
    defaultValues:{
      name:"",
      tel: "",
      mail: "",
      house_types:"",
      area:"",
      main_district:"",
      sub_district:"",
      message:"",
    }
  });
  const sendFormStatusModal = ()=>{
    setFormStatus(true)

    setTimeout(()=>{
      setFormStatus(false)
    },5000)
  }
  const onSubmit = data => {
    console.log(data)

    let url1 = process.env.PUBLIC_URL+'/assets/sendsee.php'
    fetch(url1, {
      method: 'POST',
      headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"},
      body: Object.entries(data).map(([k,v])=>{return k+'='+v}).join('&')
    }).then((response) => {
      if (response.ok) {
        console.log(response)
        console.log('ok')
        sendFormStatusModal()
        reset()
      }
    }).catch(error => console.log('error'));


  };
  return (
    <div
    className='w-full bg-[#F9F9F9] bg-cover bg-center bg-no-repeat min-h-screen relative'
    style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/section04-bg.png' })`}}
    >
      <SEO
        title={`翊鼎國際行銷 - 聯絡方式`} 
        description={`生活就像一朵花，越用心灌溉，會開出最美的模樣。`}
        name={`翊鼎國際行銷`}
        type={`digital marketing`}
        image={process.env.PUBLIC_URL+'/images/logo.png'}
      />
    <div className=' flex items-center justify-between w-full  relative  '>
      <div className='w-10/12 pt-20 md:pt-0  md:w-3/12 mx-auto md:absolute top-20 left-[0%] md:left-[8%] md:ml-[230px]' >
        {/* <img src={process.env.PUBLIC_URL+'/images/section04-title.png'} alt="" className='max-w-full md:w-1/2 my-4' /> */}
        <div className=' tracking-[4px] text-[#4D4D4D] font-bold text-xl flex items-center'>聯絡我們 <span className='ml-5 text-base tracking-[1px]'>/Contect us</span></div>
        {/* 表單 */}
        <div className='md:w-full mx-auto'>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full mx-auto pt-5 " data-aos="fade-up" data-aos-duration="1500" >
            <div className='flex flex-col w-full items-center justify-center'>
              <div className='w-full  my-1 flex items-center justify-center  space-x-4 '>
                <input type="text" className="block  bg-white  w-full
                    px-3 py-2  " placeholder="姓名"   {...register("name", { required: true})}/>
              </div>
              <div className='w-full  my-1 flex items-center justify-center space-x-4'>
                <input type="text" className="block  bg-white  w-full
                    px-3 py-2  " placeholder="聯絡電話"    {...register("tel", { required: true})}/>
              </div>
              <div className='w-full my-1  flex items-center justify-center space-x-4'>
                <input type="mail" className="block  bg-white   w-full
                    px-3 py-2 " placeholder="電子信箱"   {...register("mail")}/>
              </div>
              <div className='w-full my-1  flex items-start justify-center space-x-4'>
                <textarea name="" id=""  rows="4" className='block  bg-white   w-full
                    px-3 py-2' {...register("message")}></textarea>

              </div>
            </div>
            <input type="hidden" name="msgMailTitle" value={msgMailTitle}  {...register("msgMailTitle", { required: true})}/>
            <div className='text-[#5C2C1F] font-bold text-sm w-full mx-auto mt-4'>
              <div className=' flex mt-2 text-xs md:text-base items-center justify-center'>
                <input type="checkbox"  className='px-1 mr-1' id="checkit" name="checkit" defaultChecked {...register("checkit", { required: true})}/>
                <div className='text-black ml-2 text-xs  underline-offset-2 cursor-pointer' onClick={()=>setIsOpen(true)}>本人已知悉，<span className=''>『個人資料蒐集聲明事項』</span></div>
              </div>
            </div>
            <div className='flex w-full mx-auto gap-4 my-3 md:mt-8'>
              <button className='border-black hover:bg-white border-2 px-2 py-2   font-bold w-full    text-md tracking-wide text-black' type='submit'>送出</button>
              <button className='border-black hover:bg-white border-2 px-2 py-2  font-bold w-full   text-md tracking-wide text-black' type='reset'>清除</button>
            </div>
            <div>{error}  {mailSent}</div>            
          </form>
        </div>

      </div>

      
    </div>
    <div className='fixed bottom-0 bg-[#024E6C] md:pl-[230px] py-4 px-6 w-full'>
      <img src={process.env.PUBLIC_URL+'/images/section04-footer.png'} alt=""  className='mx-auto max-w-full w-10/12'/>
    </div>
  </div>
  )
}

export default Section4