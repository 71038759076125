import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter , Routes, Route ,useLocation,Hashrouter} from 'react-router-dom';
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import HomeLayout from './Layout/HomeLayout';
import Home from './Pages/Home'
import Section11 from './Pages/Section1-1';
import Section12 from './Pages/Section1-2';
import Section13 from './Pages/Section1-3';
import Section2 from './Pages/Section2';
import Section31 from './Pages/Section3-1';
import Section32 from './Pages/Section3-2';
import Section4 from './Pages/Section4';
import Work from './Pages/Work';

function App() {

  useEffect(() => {
    setTimeout(function() {
        AOS.init({
          
        });
    }, 100);
    // AOS.refresh()
  }, []);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes> 
          <Route path="/" element={<HomeLayout/> }>
            <Route path="" element={<Home />} />
            <Route path="section1-1" element={<Section11 />} />
            <Route path="section1-2" element={<Section12 />} />
            <Route path="section1-3" element={<Section13 />} />
            <Route path="section2" element={<Section2 />} />
            <Route path="section3-1" element={<Section31 />} />
            <Route path="section3-2" element={<Section32 />} />
            <Route path="section4" element={<Section4 />} />
            <Route path="work" element={<Work  />} />
          </Route>

        </Routes>

      </BrowserRouter>

    </HelmetProvider>
  );
}

export default App;
