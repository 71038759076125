import React from 'react'
import SEO from '../Components/SEO'
function Section13() {
  return (
    <div
      className='w-full bg-[#F9F9F9] bg-cover bg-center bg-no-repeat min-h-screen  '
      style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/section01-3_cbg.png' })`}}
    >
      <SEO
        title={`翊鼎國際行銷 - 年度里程`} 
        description={`生活就像一朵花，越用心灌溉，會開出最美的模樣。`}
        name={`翊鼎國際行銷`}
        type={`digital marketing`}
        image={process.env.PUBLIC_URL+'/images/logo.png'}
      />
      <div className=' fixed -bottom-16 md:-bottom-[10%] md:-right-[15%]'><img src={process.env.PUBLIC_URL+'/images/section01-3_cp01.png'} alt="" className='max-w-full' /></div>

      <div className=' flex h-full items-center justify-between pt-[5%]  md:pr-20 '>
        <div className='md:w-[20%] md:ml-[300px] w-9/12 mx-auto pt-10 '>
          <img src={process.env.PUBLIC_URL+'/images/section01-3-text.png'} alt="" className='max-w-full w-full' data-aos="fade-up" data-aos-duration="1000"/>
          </div>
        <div className='w-'>
            
        </div>
        
      </div>
    </div>
  )
}

export default Section13