export const projects = [
  {id:'1',  year:'2018',area:'青埔',build_type:'住宅大樓',house:'230戶',cover_image:'project01.png',title:'璟都 Home1',},
  {id:'2',  year:'2018',area:'中路',build_type:'住宅大樓',house:'290戶',cover_image:'project02.png',title:'璟都柏悅',},
  {id:'3',  year:'2018',area:'青埔',build_type:'住宅大樓',house:'93戶', cover_image:'project03.png',title:'璟都 V-PARK',}, 
  {id:'4',  year:'2018',area:'青埔',build_type:'住宅大樓',house:'120戶',cover_image:'project04.png',title:'璟都帝一莊',}, 
  {id:'5',  year:'2018',area:'中路',build_type:'住宅大樓',house:'105戶',cover_image:'project05.png',title:'合新讀樂樂',},  

  {id:'6',  year:'2019',area:'三重',build_type:'住宅大樓',house:'72戶', cover_image:'project06.png',title:'合新大河公園',},  
  {id:'7',  year:'2019',area:'林口',build_type:'住宅大樓',house:'235戶',cover_image:'project07.png',title:'和州氧樂多',},  
  {id:'8',  year:'2019',area:'土城',build_type:'住宅大樓',house:'88戶', cover_image:'project08.png',title:'樂晴市樂多區',},  
  {id:'9',  year:'2019',area:'桃園',build_type:'住宅大樓',house:'44戶', cover_image:'project09.png',title:'瑜璟芳華',},  
  {id:'10', year:'2019',area:'員林',build_type:'住宅大樓',house:'205戶',cover_image:'project10.png',title:'合陽尊峰',},  
  {id:'11', year:'2019',area:'土城',build_type:'住宅大樓',house:'142戶',cover_image:'project11.png',title:'樂晴市樂郡區',},  
  {id:'12', year:'2019',area:'新店',build_type:'住宅大樓',house:'109戶',cover_image:'project12.png',title:'城易迅',},  

  {id:'13', year:'2020',area:'三重',  build_type:'住宅大樓',house:'129戶',cover_image:'project13.png',title:'合康心綻',},  
  {id:'14', year:'2020',area:'機捷A7',build_type:'住宅大樓',house:'221戶',cover_image:'project14.png',title:'和洲金剛',},  
  {id:'15', year:'2020',area:'土城',  build_type:'住宅大樓',house:'109戶',cover_image:'project15.png',title:'勤樸丰硯',},  
  {id:'16', year:'2020',area:'青埔',  build_type:'住宅大樓',house:'109戶',cover_image:'project16.png',title:'合康心上城',},  
  {id:'17', year:'2020',area:'土城',  build_type:'住宅大樓',house:'160戶',cover_image:'project17.png',title:'心晴天',},  

  {id:'18', year:'2021',area:'員林', build_type:'住宅大樓',house:'299戶',cover_image:'project18.png',title:'合陽鼎峰',},  
  {id:'19', year:'2021',area:'中壢', build_type:'住宅大樓',house:'138戶',cover_image:'project19.png',title:'松義美學',},  
  {id:'20', year:'2021',area:'中和', build_type:'住宅大樓',house:'220戶',cover_image:'project20.png',title:'灣岸Villa',},  
  {id:'21', year:'2021',area:'基隆', build_type:'住宅大樓',house:'107戶',cover_image:'project21.png',title:'僑家大院',},  
  {id:'22', year:'2021',area:'淡水', build_type:'住宅大樓',house:'98戶', cover_image:'project22.png',title:'新潤心苑',},  
  {id:'23', year:'2021',area:'桃園', build_type:'住宅大樓',house:'248戶',cover_image:'project23.png',title:'合康檜邑',},  
  {id:'24', year:'2021',area:'三重', build_type:'住宅大樓',house:'61戶', cover_image:'project24.png',title:'柏克萊',},  
  {id:'25', year:'2021',area:'淡水', build_type:'住宅大樓',house:'42戶', cover_image:'project25.png',title:'聯硯繪',},  

  {id:'26', year:'2022',area:'青埔', build_type:'住宅大樓',house:'91戶',   cover_image:'project26.png',title:'菁美學',},  
  {id:'27', year:'2022',area:'淡水', build_type:'住宅大樓',house:'153戶',  cover_image:'project27.png',title:'合謙飛時代',},  
  {id:'28', year:'2022',area:'蘆竹', build_type:'住宅大樓',house:'79戶',   cover_image:'project28.png',title:'傳佳馥',},  
  {id:'29', year:'2022',area:'青埔', build_type:'住宅大樓',house:'115戶',  cover_image:'project29.png',title:'和發自由之丘',},  

  {id:'30', year:'2023',area:'中壢', build_type:'住宅大樓',house:'225戶', cover_image:'project30.png',title:'傳佳緻',},  
  {id:'31', year:'2023',area:'永康', build_type:'住宅大樓',house:'102戶', cover_image:'project31.png',title:'和發幸福城',},  
  {id:'32', year:'2023',area:'三民', build_type:'住宅大樓',house:'161戶', cover_image:'project32.png',title:'和紘大景旭',},  
  {id:'33', year:'2023',area:'土城', build_type:'住宅大樓',house:'57戶',  cover_image:'project33.png',title:'和毅明金',},  
  {id:'34', year:'2023',area:'安平', build_type:'住宅大樓',house:'36戶',  cover_image:'project34.png',title:'金日升虹韻',},  
  {id:'35', year:'2023',area:'安南', build_type:'住宅大樓',house:'132戶', cover_image:'project35.png',title:'昕暉詠翠',},  

]
 


export const works=[
  {id:'01',banner:'banner01.png',  title:'菁美學',base:'桃園市中壢區青埔六街、青埔八街',centre:'桃園市中壢區青埔路二段與青商路口',floor:'A棟15F、B棟12F，地下三層共91戶',size:'25-41坪',company:'寶佳建設股份有限公司',website:'https://a18art.com/',fb:'https://ppt.cc/f9orDx',yt:'',house591:''},
  {id:'02',banner:'banner02.png',  title:'合謙飛時代',base:'新北市淡水區濱海路一段',centre:'新北市淡水區濱海路一段與與濱海路一段111巷交叉口',floor:'A棟15樓、B棟14樓、地下三層，共153戶',size:'2房25~28坪｜3房33~38坪',company:'合謙建設股份有限公司',website:'https://reurl.cc/rZgk9y',fb:'https://www.facebook.com/profile.php?id=100083047013089',yt:'https://youtu.be/9JAJqJidGDA',house591:'https://newhouse.591.com.tw/131175'},
  {id:'03',banner:'banner03.png',  title:'嘉潤家泰氧之森',base:'新北市淡水區坪頂路88號',centre:'新北市淡水區中正東路二段55號',floor:'地上10層、地下4層、237戶',size:'17~31坪',company:'嘉潤建設開發股份有限公司、家泰建設開發股份有限公司',website:'https://pureresort.tw/',fb:'https://www.facebook.com/profile.php?id=100092358672448',yt:'',house591:''},
  {id:'04',banner:'banner04.png',  title:'和發自由之丘',base:'桃園市中壢區高鐵站前西路二段',centre:'桃園市中壢區青昇路237號旁',floor:'地上15層、地下3層、總戶數115戶',size:'24-42坪',company:'和發建設股份有限公司',website:'https://www.maizizi.vaserver.com/free/',fb:'https://www.facebook.com/profile.php?id=100083919200216',yt:'https://www.youtube.com/watch?v=B7oVyxdVfl0&t=377s&ab_channel=%E5%AE%89%E5%A8%9C%E9%A6%AD%E6%88%BF%E8%A1%9',house591:'https://newhouse.591.com.tw/132306'},
  {id:'05',banner:'banner05.png',  title:'傳佳馥',base:'桃園市蘆竹區中興四街2巷',centre:'桃園市蘆竹區中興四街2巷16號',floor:'12F / B2，共79戶',size:'23-39坪',company:'傳佳建設股份有限公司',website:'https://case.jun-yi.tw/excellent/',fb:'https://www.facebook.com/profile.php?id=100077048966109&mibextid=LQQJ4d',yt:'',house591:''},
  {id:'06',banner:'banner06.png',  title:'和紘大景旭',base:'高雄市三民區建國三路、中庸街口',centre:'高雄市三民區力行路1-2號',floor:'28F / B5，共161戶',size:'29-47坪',company:'和紘建設股份有限公司',website:'https://skyclub.com.tw',fb:'https://m.facebook.com/skyclub915',yt:'',house591:''},
  {id:'07',banner:'banner07.png',  title:'合康檜邑',base:'桃園市桃園區日光路',centre:'桃園市桃園區青溪三路及朝陽街口',floor:'地上十四層、地下四層共248戶',size:'26~46坪',company:'合康建設股份有限公司',website:'https://xn--8pro2w9rjsi2b.tw/',fb:'https://www.facebook.com/profile.php?id=100066399735832&mibextid=2JQ9oc',yt:'',house591:''},
  {id:'08',banner:'banner08.png',  title:'傳佳緻',base:'桃園市中壢區雙嶺段1478地號(松義街/松仁路)',centre:'桃園市中壢區松勇路．松勇一街口',floor:'地上13、14層、地下3層、213戶住家、12戶店面',size:'2房25坪｜3房28~37坪｜4房46坪',company:'傳佳建設股份有限公司',website:'http://www.bonjourlife.com.tw/',fb:'https://www.facebook.com/profile.php?id=100085631757277',yt:'https://www.youtube.com/watch?v=ZBRYBpZYGH0',house591:'https://goo.gl/maps/iVdn3dH57C6hBmJ5A'},
  {id:'09',banner:'banner09.png',  title:'和紘大景閲',base:'台南市歸仁區歸仁十四路與光明街口',centre:'台南市歸仁區中正南路二段12號',floor:'A棟地上12層、B棟地上7層、地下2層、90戶',size:'26-39坪',company:'和紘建設股份有限公司',website:'https://golden-designer.com/Home_80/',fb:'https://www.facebook.com/profile.php?id=100090775773985',yt:'',house591:''},
  {id:'10',banner:'banner10.png',  title:'和發幸福成',base:'永康區西灣里永榮路與永善二街交叉口',centre:'永康區公園路、永善一街口',floor:'13F/B3(AB棟)、14F/B3(C棟)、共102戶',size:'住家25.08坪~38.03坪，店面18.33坪~38.66坪',company:'和發建設股份有限公司',website:'https://www.hofahappiness.com.tw/',fb:'https://www.facebook.com/profile.php?id=100086471104365',yt:'',house591:''},
  {id:'11',banner:'banner11.png',  title:'和洲興綻前',base:'中壢區豐興段129地號',centre:'桃園市中壢區新生路512-1號',floor:'地上15層、地下3層、70戶住家、4店面',size:'25~36坪',company:'和洲建設',website:'',fb:'',yt:'',house591:''},
  {id:'12',banner:'banner12.png',  title:'禹順森品',base:'高雄市小港區松青路192巷2號',centre:'高雄市小港區松青路192巷2號',floor:'樓層4樓 戶數14戶',size:'地坪24-33坪、建坪49-55坪',company:'禹順建設股份有限公司',website:'',fb:'https://www.facebook.com/profile.php?id=61550246673650',yt:'',house591:''},
  {id:'13',banner:'banner13.png',  title:'昕暉詠翠',base:'台南市安南區總安段1068地號',centre:'台南市安南區區北安路三段．新宅五街口',floor:'地上13.14層、地下3層、126戶住家、6戶店面',size:'26-37坪',company:'昕暉建設股份有限公司',website:'https://www.south-casa.com.tw/',fb:'https://www.facebook.com/profile.php?id=100086047560305',yt:'',house591:''},
  {id:'14',banner:'banner14.png',  title:'合陽鼎峰',base:'彰化縣員林市三多七街',centre:'彰化縣員林市員林大道四段、三多街口',floor:'11棟、地上13層、地下2層、299戶住家',size:'28-47坪',company:'合陽建設股份有限公司',website:'https://case.jun-yi.tw/dingfong/?mibextid=Zxz2cZ',fb:'https://www.facebook.com/a8315888?mibextid=LQQJ4d',yt:'',house591:''},
  {id:'15',banner:'banner15.png',  title:'合新城峰',base:'彰化縣彰化市延平段979地號',centre:'彰化市中山路一段612號1樓',floor:'15F／B3，146戶',size:'25-33坪',company:'合新建設股份有限公司',website:'https://www.hexincitytop.com.tw',fb:'https://www.facebook.com/profile.php?id=61552048294110',yt:'',house591:''},
  {id:'16',banner:'banner16.png',  title:'和毅明金',base:'新北市土城區金城路二段111-號',centre:'新北市土城區金城路二段，學士路33巷口',floor:'14F／B4，57戶',size:'40-46坪',company:'和毅建設股份有限公司',website:'https://lixinpro.com/A8OOW',fb:'https://lixinpro.com/UAEKF',yt:'',house591:''},
]