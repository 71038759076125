import React, { useState } from 'react'
import { Link,useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FaBars } from "react-icons/fa6";
function Navbar() {
  const[ative ,setAtive] = useState(false)
  const [currentItem, setCurrentItem] = useState('');
  const location = useLocation();
  
  const menuItem = [
    {title:"品牌價值",path:"section1-1",submenu:[{title:"創辦心誌",path:"section1-1"},{title:"品牌故事",path:"section1-2"},{title:"年度里程",path:"section1-3"}]},
    {title:"團隊介紹",path:"section2",},
    {title:"業績作品",path:"section3-1",submenu:[{title:"熱銷個案",path:"section3-1"},{title:"翊鼎之作",path:"section3-2"}]},
    {title:"聯絡方式",path:"section4",},
  ]
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <> 
      {/* desktop */}
      <div className='w-[230px] bg-[#F2F3F4] h-screen fixed top-0 left-0 drop-shadow-3xl py-10  flex-col z-50 hidden md:flex'>
        <Link to="/" className='flex justify-center items-center py-2'>
          <img src={process.env.PUBLIC_URL+'/images/logo.png'} alt="" />
        </Link>
        <div className={`flex flex-col items-center font-extrabold space-y-2 text-xl divide-y divide-black/70 mt-auto w-full `}>
          {menuItem.map((item,index)=>{
            return(
              <Link 
                key={item.title}
                to={item.path}
                className={`w-full text-center py-4 transition-all`}
                onClick={()=>setCurrentItem(item.path)}
              >
                <div className={`  tracking-[4px] hover:tracking-[6px] ${location.pathname.substring(1) === item.path ? '  text-[#014C69] ': ' text-[#4d4d4d]'}`}>{item.title}</div>
                <AnimatePresence>
                {currentItem === item.path && item.submenu &&(
                  <motion.div 
                    className={` overflow-hidden  flex flex-col bg-[#E7E8E4] w-full mt-2 `}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                  {item.submenu.map((subitem,index)=>{
                    return(
                      <Link key={subitem.title} to={subitem.path} className={`transition-all tracking-[4px] hover:tracking-[6px] py-3 ${location.pathname.substring(1) === subitem.path ? 'text-white bg-[#014C69]   ': ' text-[#4d4d4d]'}`}>{subitem.title}</Link>
                    )
                  })}
                  </motion.div>)
                }
                </AnimatePresence>

              </Link>
            )
          })}
        </div>
      </div>
      {/* mobile */}
      <div className=' md:hidden '>
        <div className=' bg-slate-100/0 px-4 py-4  fixed w-full z-50' onClick={toggleMenu}>
          <FaBars size={20} />
        </div>
     
        <div className={` bg-white drop-shadow-xl absolute z-20 top-0 left-0 w-full h-screen transition-all duration-500 delay-100    ${isOpen ? ' translate-y-[0%]' :  ' -translate-y-[130%]'} `}>
          <Link 
            to="/" 
            className='flex justify-center items-center py-4'
            onClick={()=>{
              setIsOpen(!isOpen);
            }}
          >
            <img src={process.env.PUBLIC_URL+'/images/logo.png'} alt="" />
          </Link>
          <div className={`flex flex-col md:items-center font-extrabold space-y-2   mt-auto w-full `}>
            {menuItem.map((item,index)=>{
              return(
                <Link 
                  key={item.title}
                  to={item.path}
                  className={`w-full text-center py-5 transition-all`}
                  onClick={()=>{
                    setCurrentItem(item.path)
                    setIsOpen(!isOpen);
                  }}
                >
                  <div className={` text-lg tracking-[4px] hover:tracking-[6px] ${location.pathname.substring(1) === item.path ? '  text-[#014C69] ': ' text-[#4d4d4d]'}`}>{item.title}</div>
       
                  {item.submenu &&(
                    <motion.div 
                      className={` overflow-hidden  flex flex-col justify-center gap-4 bg-gray-300/20 w-full mt-2 text-lg `}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                    {item.submenu.map((subitem,index)=>{
                      return(
                        <Link 
                          key={subitem.title} 
                          to={subitem.path} 
                          className={` transition-all tracking-[4px] hover:tracking-[6px] py-3 ${location.pathname.substring(1) === subitem.path ? 'text-white bg-[#014C69]   ': ' text-[#4d4d4d]'}`}
                          onClick={()=>{
                            setIsOpen(!isOpen);
                          }}
                        >
                          {subitem.title}
                        </Link>
                      )
                    })}
                    </motion.div>)
                  }
            

                </Link>
              )
            })}
          </div>
        </div>
     
      </div>



    </>

  )
}

export default Navbar