import React from 'react'
import { Helmet } from 'react-helmet-async';
import SEO from '../../Components/SEO';
import { Link,useLocation } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay,FreeMode, Navigation, Thumbs } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FaCaretLeft,FaCaretRight } from "react-icons/fa6";
import {works} from '../workitem'
function Index() {
  return (
    <div
      className='w-full bg-[#F9F9F9] bg-cover bg-center bg-no-repeat min-h-screen'
      style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/home-bg.png' })`}}
    >
      <SEO
        title={`翊鼎國際行銷`} 
        description={`生活就像一朵花，越用心灌溉，會開出最美的模樣。`}
        name={`翊鼎國際行銷`}
        type={`digital marketing`}
        image={process.env.PUBLIC_URL+'/images/logo.png'}
      />
      <div className='md:pl-[230px]'>
        <div className=' flex flex-col h-screen items-center justify-between pt-[6%]  md:p-8 '>
          <div className='flex w-full h-full items-center justify-between'>
            <div className='md:w-2/3'></div>
            <div className='w-10/12 mx-auto md:w-1/3 pb-[2vw]'>
                <img src={process.env.PUBLIC_URL+'/images/home-text.png'} alt="" className='max-w-full w-full'  data-aos="fade-up" data-aos-duration="1000" />
            </div>
          </div>
          <div className='w-10/12 mx-auto my-auto flex flex-col  relative mb-14 '>

            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              spaceBetween={20}
              slidesPerView={1.5}
              centeredSlides={true}
              loopedSlides={2}
              loop={true}
              center
              breakpoints={{
                420: {
                  slidesPerView: 1.5,
                  spaceBetween:40
                },
                768: {
                  slidesPerView: 5,
                  spaceBetween:20
                },
                1024:{
                  slidesPerView: 5,
                  spaceBetween:20
                }
              }}
              modules={[Autoplay,FreeMode, Navigation, Thumbs]}
              className="mySwiper2 w-full"
              navigation={{
                nextEl: ".slidenext, .slidenext2",
                prevEl: ".slideprev, .slideprev2"
              }}
            >
              {
                works &&
                works.map((item,index)=>{
                  return(
                    <SwiperSlide key={'banner'+index} >
                      <Link  to={`/work?id=${item.id}`} className=' transition-all duration-500 hover:brightness-105'>
                          <img src={process.env.PUBLIC_URL+'/images/works/cover'+item.id+'.png'} alt={item.title}  className="w-full"/>
                      </Link>
                    </SwiperSlide>
                  )
                })
              }

            </Swiper>
            <div className=' w-[112%] -left-[6%]  gap-10 justify-between    flex items-center  absolute top-0 bottom-0 z-10 pointer-events-none '>
              <button   className="slideprev2 rounded-full text-black border-black border w-10 h-10 flex justify-center items-center pointer-events-auto ">
                <FaCaretLeft size={30} />
              </button>
              <button   className="slidenext2 rounded-full text-black border-black border w-10 h-10 flex justify-center items-center pointer-events-auto">
                <FaCaretRight size={30} /> 
              </button>
            </div>

            </div>

          
        </div>
      </div>


    </div>
  )
}

export default Index