import React, { useState } from 'react'
import {projects} from './workitem'
import { motion, AnimatePresence } from "framer-motion";
import SEO from '../Components/SEO';
function Section32() {
  const [ active , setActive] = useState('')
  const [currentData , setCurrentData] =useState([])
  const [currentYear , setCurrentYear] =useState('')
  const years = [
    '2018','2019','2020','2021','2022','2023'
  ]
  const selectByYear = (year) =>{
    const filteredProjects = projects.filter(project => project.year === year);
    setCurrentData(filteredProjects)
  }
  return (
    <div
    className='w-full bg-[#F9F9F9]  bg-cover bg-center bg-no-repeat min-h-screen bg-fixed'
    style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/section03-bg.png' })`}}
    >
      <SEO
        title={`翊鼎國際行銷 - 翊鼎之作`} 
        description={`生活就像一朵花，越用心灌溉，會開出最美的模樣。`}
        name={`翊鼎國際行銷`}
        type={`digital marketing`}
        image={process.env.PUBLIC_URL+'/images/logo.png'}
      />
    <div className='w-full flex flex-col h-screen  relative  overflow-y-auto '>
      <div className='pt-14 pb-5 md:py-10 px-10 md:ml-[230px]'>
        {/* <img src={process.env.PUBLIC_URL+'/images/section03-2-title.png'} alt="" className='max-w-full' /> */}
        <div className=' tracking-[4px] font-bold text-[#4D4D4D] text-xl flex items-center'>翊鼎之作 <span className='ml-5 text-base tracking-[1px]'>/Projects</span></div>

      </div>
      <div className={`pl-[230px]   w-full h-full   bottom-0    z-10  flex flex-col items-center justify-end transition-all duration-500 ${active === '' ? '-translate-x-1/4 md:-translate-x-0 ' : active %2 ===0 ? 'translate-x-[0px]   md:translate-x-[0px]': '-translate-x-[60%]  md:translate-x-[0px]'} `}>
      {years.map((item,index)=>{
        return(
          <div 
            key={index} 
            className=' flex flex-col justify-center items-center cursor-pointer group  relative  '
            // ${active %2 ===0 ? 'translate-x-[300px]   md:translate-x-[315px]': 'translate-x-[50px]  md:translate-x-[0px]'}
            onClick={()=>{
              setActive(index)
              selectByYear(item)
              setCurrentYear(item)
              }}>
            <div  className={`flex flex-col items-center relative w-full `}>
                <div className='w-3 h-3  bg-[#014C69] rounded-full group-hover:bg-rose-800  relative point'></div>
                <div className={`w-[1px]  bg-[#014C69] transition-all duration-300 delay-100 ${index === active ? 'h-[34vh] md:h-[50vh]': 'h-10'}`}></div>
                <div className={`absolute -top-2 ${index % 2 === 0 ? ' right-4 ' : ' left-4 '}   `}>{item}</div>

            </div>
            {
              item === currentYear &&
              <motion.div 
                initial={{ opacity: 0}}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{delay:0.2, duration: 0.3, ease: "easeInOut" }}
                className={`w-[220px] md:w-[500px] absolute flex flex-wrap h-full   justify-between  transition-all top-6  ${index % 2 === 0 ? ' right-10 ' : ' left-14 '}`}
              >
                {currentData.map((pdata,index)=>{
                  return(
                    <div className='md:w-1/2 w-1/2 flex flex-col md:flex-row md:gap-3 mb-4 pl-2'>
                      <div className='md:w-[120px] '>
                        <div className='pt-[76%] relative  '>
                          <img src={process.env.PUBLIC_URL+'/images/projects/'+pdata.cover_image} alt="" className='w-full max-w-full absolute top-1/2 left-0 -translate-y-1/2 object-cover  h-full   '/>
                        </div>
                      </div>

                      <div>
                        <div className='text-sm font-semibold tracking-widest break-keep'>{pdata.title}</div>
                        <div className='text-xs mt-2 flex flex-col  space-y-2 font-sans'>
                          <div className='flex'><span className=' break-keep mr-1'>{pdata.area}</span> | <span className='ml-1'>{pdata.build_type}   </span>  </div>
                          <div className='flex'><span className=' break-keep mr-1'>戶數:</span> <span>{pdata.house} </span>  </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </motion.div>
                
            }
            
          </div>
        )
      })}
      <div className='w-[1px]  bg-[#014C69] h-1/2  md:h-12 relative  '>
        <div className='w-[1px]  bg-[#014C69]  h-14 absolute -bottom-14 left-0  '></div>
      </div>
      </div>


    </div>
  </div>
  )
}

export default Section32