import React from 'react'
import SEO from '../Components/SEO'
function Section12() {
  return (
    <div
      className='w-full bg-[#F9F9F9] bg-cover bg-center bg-no-repeat min-h-screen '
      style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/section01-2_cbg.png' })`}}
    >
      <SEO
        title={`翊鼎國際行銷 - 品牌故事`} 
        description={`生活就像一朵花，越用心灌溉，會開出最美的模樣。`}
        name={`翊鼎國際行銷`}
        type={`digital marketing`}
        image={process.env.PUBLIC_URL+'/images/logo.png'}
      />
      <div className=' absolute bottom-0 md:top-0 md:left-0'><img src={process.env.PUBLIC_URL+'/images/section01-2_cp01.png'} alt="" className='max-w-full' /></div>
      <div className=' flex h-full items-center justify-between pt-[16%] md:pl-[20%] md:pr-20 '>
        <div className='md:w-2/3'></div>
        <div className='md:w-1/4 w-9/12 mx-auto'>
            <img src={process.env.PUBLIC_URL+'/images/section01-2-text.png'} alt="" className='max-w-full w-full' data-aos="fade-up" data-aos-duration="1000"/>
        </div>
        
      </div>
    </div>
  )
}

export default Section12